import _export from "../internals/export";
import _toObject from "../internals/to-object";
import _objectKeys from "../internals/object-keys";
import _fails from "../internals/fails";
var $ = _export;
var toObject = _toObject;
var nativeKeys = _objectKeys;
var fails = _fails;
var FAILS_ON_PRIMITIVES = fails(function () {
  nativeKeys(1);
}); // `Object.keys` method
// https://tc39.es/ecma262/#sec-object.keys

$({
  target: "Object",
  stat: true,
  forced: FAILS_ON_PRIMITIVES
}, {
  keys: function keys(it) {
    return nativeKeys(toObject(it));
  }
});
export default {};